import { useLocation } from 'react-router-dom'
import HeaderNavBar from './HeaderNavBar'
import LogoHeader from './LogoHeader'
import { isHideGlobalHeaderPath } from '../../../helpers'
import { useEffect } from 'react'
import { useSiteContext } from '../../../context/site/SiteProvider'
import { useQueryClient } from '@tanstack/react-query'
import { userKeys } from '../../../queries/user/keyFactory'
import { ICustomerData, IUserProfile } from '../../../queries/user/types'
import { Helmet } from 'react-helmet'

const Header = () => {
  const location = useLocation()
  const {
    setMainHeaderDivHeight,
    setMobileMainHeaderDivHeight,
    getFlashSaleBanners,
    mainHeaderDivHeight,
    mobileMainHeaderDivHeight,
    isDownForMaintenance,
  } = useSiteContext()

  useEffect(() => {
    getFlashSaleBanners()
  }, [])

  window.onresize = () => {
    setMainHeaderDivHeight(mainHeaderDivHeight)
    setMobileMainHeaderDivHeight(mobileMainHeaderDivHeight)
  }

  const queryClient = useQueryClient()
  const userProfile = queryClient.getQueryData(
    userKeys.userProfile(),
  ) as IUserProfile
  const customerData = queryClient.getQueryData(
    userKeys.customer(),
  ) as ICustomerData

  return (
    <>
      <Helmet>
        <meta
          name="iadvizeCustomersId"
          id="iadvizeCustomersId"
          content={
            userProfile?.customers_id ? userProfile.customers_id.toString() : ''
          }
        />
        <meta
          name="iadvizeCustomersName"
          id="iadvizeCustomersName"
          content={
            (userProfile?.customers_firstname
              ? userProfile.customers_firstname
              : '') +
            ' ' +
            (userProfile?.customers_lastname
              ? userProfile.customers_lastname
              : '')
          }
        />
        <meta
          name="iadvizeCustomersEmailAddress"
          id="iadvizeCustomersEmailAddress"
          content={
            userProfile?.customers_email_address
              ? userProfile.customers_email_address
              : ''
          }
        />
        <meta
          name="iadvizePriceLevelsId"
          id="iadvizePriceLevelsId"
          content={
            customerData?.price_levels_id
              ? customerData.price_levels_id.toString()
              : ''
          }
        />
        <meta name="iadvizeTransactionId" content="" />
      </Helmet>
      {isHideGlobalHeaderPath(location.pathname) || isDownForMaintenance ? (
        <LogoHeader linkToHome={!isDownForMaintenance} />
      ) : (
        <HeaderNavBar />
      )}
    </>
  )
}

export default Header
